// ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
// This file was automatically generated using npm run codegen
// If you want to alter this file please do so via the above mentioned command
// +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
import * as Types from "../../shared/types/schema";

import { gql } from "@apollo/client";
import * as ApolloReactCommon from "@apollo/client";
import * as ApolloReactHooks from "@apollo/client";
const defaultOptions = {} as const;
export type PreferenceFragment = {
  __typename?: "Preference";
  ridesFullyBooked: {
    __typename?: "RidesFullyBookedPreference";
    active: boolean;
    utilization: number;
  };
  scheduleExpiryReminder: {
    __typename?: "ScheduleExpiryReminderPreference";
    active: boolean;
  };
  productNews: { __typename?: "ProductNewsPreference"; active: boolean };
};

export type GetUserQueryVariables = Types.Exact<{ [key: string]: never }>;

export type GetUserQuery = {
  __typename?: "Query";
  getUser: {
    __typename?: "User";
    id: string;
    displayName: string;
    mail?: string | null;
    language: Types.Language;
    preference: {
      __typename?: "Preference";
      ridesFullyBooked: {
        __typename?: "RidesFullyBookedPreference";
        active: boolean;
        utilization: number;
      };
      scheduleExpiryReminder: {
        __typename?: "ScheduleExpiryReminderPreference";
        active: boolean;
      };
      productNews: { __typename?: "ProductNewsPreference"; active: boolean };
    };
  };
};

export type UpdateUserPreferenceMutationVariables = Types.Exact<{
  language: Types.Language;
  preference: Types.PreferenceInput;
}>;

export type UpdateUserPreferenceMutation = {
  __typename?: "Mutation";
  updateUserPreference: {
    __typename?: "User";
    id: string;
    language: Types.Language;
    preference: {
      __typename?: "Preference";
      ridesFullyBooked: {
        __typename?: "RidesFullyBookedPreference";
        active: boolean;
        utilization: number;
      };
      scheduleExpiryReminder: {
        __typename?: "ScheduleExpiryReminderPreference";
        active: boolean;
      };
      productNews: { __typename?: "ProductNewsPreference"; active: boolean };
    };
  };
};

export const PreferenceFragmentDoc = gql`
  fragment Preference on Preference {
    ridesFullyBooked {
      active
      utilization
    }
    scheduleExpiryReminder {
      active
    }
    productNews {
      active
    }
  }
`;
export const GetUserDocument = gql`
  query GetUser {
    getUser {
      id
      displayName
      mail
      language
      preference {
        ...Preference
      }
    }
  }
  ${PreferenceFragmentDoc}
`;

/**
 * __useGetUserQuery__
 *
 * To run a query within a React component, call `useGetUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<GetUserQuery, GetUserQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<GetUserQuery, GetUserQueryVariables>(
    GetUserDocument,
    options
  );
}
export function useGetUserLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetUserQuery, GetUserQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<GetUserQuery, GetUserQueryVariables>(
    GetUserDocument,
    options
  );
}
export function useGetUserSuspenseQuery(
  baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
    GetUserQuery,
    GetUserQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useSuspenseQuery<GetUserQuery, GetUserQueryVariables>(
    GetUserDocument,
    options
  );
}
export type GetUserQueryHookResult = ReturnType<typeof useGetUserQuery>;
export type GetUserLazyQueryHookResult = ReturnType<typeof useGetUserLazyQuery>;
export type GetUserSuspenseQueryHookResult = ReturnType<typeof useGetUserSuspenseQuery>;
export const UpdateUserPreferenceDocument = gql`
  mutation UpdateUserPreference($language: Language!, $preference: PreferenceInput!) {
    updateUserPreference(language: $language, preference: $preference) {
      id
      language
      preference {
        ...Preference
      }
    }
  }
  ${PreferenceFragmentDoc}
`;

/**
 * __useUpdateUserPreferenceMutation__
 *
 * To run a mutation, you first call `useUpdateUserPreferenceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserPreferenceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserPreferenceMutation, { data, loading, error }] = useUpdateUserPreferenceMutation({
 *   variables: {
 *      language: // value for 'language'
 *      preference: // value for 'preference'
 *   },
 * });
 */
export function useUpdateUserPreferenceMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateUserPreferenceMutation,
    UpdateUserPreferenceMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    UpdateUserPreferenceMutation,
    UpdateUserPreferenceMutationVariables
  >(UpdateUserPreferenceDocument, options);
}
export type UpdateUserPreferenceMutationHookResult = ReturnType<
  typeof useUpdateUserPreferenceMutation
>;
