import { Switch } from "@flixbus/honeycomb-react";
import { LanguageSwitcher, LocaleListItem } from "@flixbus-phx/marketplace-common";
import * as React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import {
  Language,
  PreferenceInput,
  ProductNewsPreference,
} from "../../../shared/types/schema";
import {
  languageToLocale,
  localeToLanguage,
  userLanguageLocales,
} from "../../helper/userLanguageLocale/userLanguageLocale";
import * as css from "../scheduleExpiryItem/ScheduleExpiryItem.scss";

type Props = {
  language: Language;
  preference: ProductNewsPreference;
  onChange: (language: Language, values: PreferenceInput["productNews"]) => void;
};

const ProductNewsItem: React.FC<Props> = ({ language, preference, onChange }) => {
  const { formatMessage } = useIntl();

  return (
    <div className={css.listItem}>
      <div>
        <p>
          <FormattedMessage id="settings.productNewsNotification.header" />
        </p>
        <p className={css.info}>
          <FormattedMessage id="settings.productNewsNotification.subheader" />
        </p>
        <p>
          <LanguageSwitcher
            currentLanguage={languageToLocale(language)}
            languages={[
              {
                region: formatMessage({ id: "navigation.switchLanguage" }),
                locales: userLanguageLocales,
              },
            ]}
            onLanguageSelected={(locale: LocaleListItem) => {
              onChange(localeToLanguage(locale), { active: preference.active });
            }}
            toggleIcon
          />
        </p>
      </div>
      <div className={css.switchWrapper}>
        <Switch
          id="product-news-switch"
          aria-label="product-news"
          defaultChecked={preference.active}
          onChange={({ currentTarget }) => {
            onChange(language, {
              active: currentTarget.checked,
            });
          }}
          extraClasses={css.switch}
        />
      </div>
    </div>
  );
};

export default ProductNewsItem;
